import { useRouteLoaderData } from 'react-router-dom';

import BalanceCounter from './Profil/BalanceCounter';
import PasswordChangeForm from './Profil/PasswordChangeForm';

//@TODO: CSS auf Module umstellen
import './style/Profil.css'

//@TODO: Get profile-pic from database (also in ../components/Navbar.js)
import imgProfile from '../img/profile.png';

function Profil() {
    const username = localStorage.username;
    const siteData = useRouteLoaderData('profil');

    const counterValues = siteData.counter_values;

    return (
        <div>
            <div className='row d-flex justify-content-center site-title'>
                Mein Profil - { username }
            </div>

            <div className='profil-headline'>Profilbild</div>
            <hr className='profil-divider' />
                <div className='d-flex justify-content-center'>
                    <img className='profil-img-lg' src={imgProfile} alt="" />
                </div>

            <div className='profil-headline'>Guthaben</div>
            <hr className='profil-divider' />
                <BalanceCounter counterValues={ counterValues } />
            

            <div className='profil-headline'>Passwort ändern</div>
            <hr className='profil-divider' />
                <PasswordChangeForm />

        </div>
    );
}

export default Profil;