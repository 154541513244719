import '../style/Footer.css'

function Footer() {
    const year = new Date().getFullYear();

    return (
        <section id="footer" className="d-flex justify-content-center section-footer">
            <p>©️ Copyright {year}</p>
        </section>
    );
}

export default Footer;