import { useEffect } from "react";

function BalanceCounter({ counterValues }) {
    useEffect(() => {
        const script = document.createElement("script");

        try {
            script.src = "../slidingCounter.js?timestamp=" + counterValues.timestamp +
                                    "&balanceCurrent=" + counterValues.balance_current + 
                                    "&counterSpeedCurrent=" + counterValues.counterSpeed_current + 
                                    "&counterSpeedDecrease=" + counterValues.counterSpeedDecrease;
            script.id = "sliding_counter";
            script.async = true;
        
            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script)
            }
        } catch(e) {
            console.log(e);
        }
    }, [ counterValues ]);

    return (
        <div className="d-flex justify-content-center align-items-end slidingCounter-doors">
            <div className='slidingCounter-box-lg align-self-center me-2'>€</div>
        
            <div className="slidingCounter-door-lg">
                <div className="slidingCounter-boxes"></div>
            </div>
        
            <div className="slidingCounter-door-lg">
                <div className="slidingCounter-boxes"></div>
            </div>

            <div className='slidingCounter-box-lg slidingCounter-comma align-self-center'>,</div>

            <div className="slidingCounter-door-lg">
                <div className="slidingCounter-boxes"></div>
            </div>

            <div className="slidingCounter-door-lg">
                <div className="slidingCounter-boxes"></div>
            </div>

            <div className="slidingCounter-door-sm">
                <div className="slidingCounter-boxes"></div>
            </div>

            <div className="slidingCounter-door-full-sm">
                <div className="slidingCounter-boxes"></div>
            </div>
        </div>
    );
}

export default BalanceCounter;