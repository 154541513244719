import { useRouteLoaderData } from 'react-router-dom';

import CalendarEntry from './Training/CalendarEntry';

import './style/Training.css'

function Training() {
    const siteData = useRouteLoaderData('training');

    const workoutData = siteData.workout_data;

    //@TODO: Wochendatum muss eingefügt werden
    //@TODO: Es muss zwischen Wochen gesprungen werden können
    //      <Link to={week - 1}><<</Link>
    return (
        <div>
            <div className="row d-flex justify-content-center site-title">
                Trainingskalender
            </div>

            <div className='d-flex justify-content-between'>
                <div>&lt;&lt;</div>
                
                <div>
                    20.08.2023 - 26.08.2023
                </div>

                <div>&gt;&gt;</div>
            </div>

            { workoutData.map((entry, key) => <CalendarEntry key={key} day={key} details={entry} />) }
            
        </div>
    );
}

export default Training;