import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

import Login from './sites/Login';
import MembersLayout from './sites/members/MembersLayout';
import Training from './sites/members/Training';

import BelohnungenLayout from './sites/members/BelohnungenLayout';
import BelohnungsListe from "./sites/members/Belohnungen/BelohnungsListe";
import Vorschlaege from './sites/members/Belohnungen/Vorschlaege';
import VerworfeneEintraege from './sites/members/Belohnungen/VerworfeneEintraege';
import AbgelehnteVorschlaege from "./sites/members/Belohnungen/AbgelehnteVorschlaege";

import Profil from './sites/members/Profil';


import fetchSiteData from './lib/backendUtils';

//@TODO: Seiten- und Komponentennamen auf Englisch umstellen (z.B. Belohnungen --> Rewards) 
const router = createBrowserRouter([
  {
    path: '/',
    element: <Login />
  },
  {
    id: 'general',
    path: '/members',
    element: <MembersLayout />,
    errorElement: <div />, //<ErrorPage />,
    loader: () => fetchSiteData("/general/balance"),
    children: [
      {
        id: 'training',
        path: 'training',
        element: <Training />,
        loader: () => fetchSiteData("/training/0") //@TODO: 0 muss dynamisch gesetzt werden
      },
      /*{
        id: 'belohnungen',
        path: 'belohnungen',
        element: <BelohnungenLayout />,
        children: [
          {
            id: 'belohnungen/liste',
            path: '',
            element: <BelohnungsListe />,
            loader: () => fetchSiteData("/rewards")
          },
          {
            id: 'belohnungen/vorschlaege',
            path: 'vorschlaege',
            element: <Vorschlaege />,
            loader: () => fetchSiteData("/rewards/proposals")
          },
          {
            id: 'belohnungen/abgelehnt',
            path: 'abgelehnt',
            element: <AbgelehnteVorschlaege />,
            loader: () => fetchSiteData("/rewards/rejected")
          },
          {
            id: 'belohnungen/verworfen',
            path: 'verworfen',
            element: <VerworfeneEintraege />,
            loader: () => fetchSiteData("/rewards/discarded")
          }
        ]
      },*/
      {
        id: 'profil',
        path: 'profil',
        element: <Profil />,
        loader: () => fetchSiteData("/profil")
      }
    ]
  }
]);

function App() {
  return <RouterProvider router={router} />;
}

//////////////////////////////////////////
//In MembersLayout
//////////////////////////////////////////
/*<Navbar></Navbar>
  <Outlet /> //Verwendet die Children aus dem Router-Objekt (Outlet muss von react-router-dom importiert werden)
<Footer></Footer>

//Zum Navigieren zwischen Routen
<Link to="/">Login</Link>

//Links in der Navigation (damit die aktuelle Seite einfach gehighlightet werden kann)
//Verwendet für className eine Funktion
//end sagt, dass die Route mit dem Link enden muss und nicht nur beginnen (ein Link zu / würde sonst für alle gelten)
<NavLink to="/" className={ ({ isActive }) => (isActive) ? " active" : "" } end>Login</NavLink>*/

export default App;