import './style/Login.css';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';

import { decryptAES, hashSHA512 } from '../lib/encryptionUtils';

//@TODO: Prüfen, ob schon eingeloggt und redirect, falls ja
function Login() {
    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.token) {
            navigate('/members/training');
        }
    }, [ navigate ]);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
  
    const [loginError, setLoginError] = useState("");

    const onSubmitForm = async e => {
        e.preventDefault();

        try {
            const body = { username, password };

            const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/login", {
                method: "POST",
                headers: {
                    "Content-type": "application/json"
                },
                body: JSON.stringify(body)
            });
            const loginResult = await response.json();

            if (loginResult.token) {
                setLoginError("")
                localStorage.setItem("token", loginResult.token);
                localStorage.setItem("username", username);

                const encryptionKey = decryptAES(loginResult.userspecific_key, hashSHA512(password));
                localStorage.setItem("dataKey", encryptionKey);
                
                //@TODO: Redirect zu Training (bzw. Seite, die aufgerufen werden sollte => kann über props übergeben werden)
                navigate('/members/training');
            } else {
                switch (loginResult.err) {
                    case "err_user_not_found":
                        setLoginError("Der Username existiert nicht");
                        break;
                    case "err_invalid_password":
                        setLoginError("Ungültiges Passwort");
                        break;
                    default: setLoginError("Ein Fehler ist aufgetreten");
                }
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    //@TODO: login form shows no suggestions for username if textfield is clicked 
    return (
        <div className="Login d-flex justify-content-center row">
            <form className="login-form col-xxl-2 col-xl-3 col-md-4 col-8" onSubmit={ onSubmitForm }>
                <input type="text" className="form-control input-login" placeholder="Username" value={username} onChange={event => setUsername(event.target.value)}/>
                <input type="password" className="form-control input-login" placeholder="Passwort" value={password} onChange={event => setPassword(event.target.value)}/>
                <button type="submit" className="form-control input-login btn-login" onClick={event => event.target.blur()}>LOGIN</button>
                <div className="login-error" name="login-error">{loginError}</div>
            </form>
        </div>
    );
}
    
export default Login;