import fetchSiteData from "../../lib/backendUtils";
import { useState } from "react";
import { useLoaderData } from "react-router-dom";

import stringifyCashValue from "../../lib/formatUtils";

function Balance() {
    const initBalance = useLoaderData().balance;
    const initNextMeetup = useLoaderData().nextMeetup;

    const [ balance, setBalance ] = useState(initBalance);
    const [ nextMeetup, setNextMeetup ] = useState(initNextMeetup);

    const updateBalance = () => {
        fetchSiteData("/general/balance").then(data => {
            data.json().then(balanceData => {
                setBalance(balanceData.balance);
                setNextMeetup(balanceData.nextMeetup);
            });
        });
    }

    const updateBalanceHandler = (e) => {
        e.stopPropagation();
        updateBalance();
    }

    return (
        <div className="d-flex justify-content-end">
            <div className="d-block me-3">
                <div className="d-flex justify-content-center mb-2">
                    Guthaben: { stringifyCashValue(balance, 2) }
                </div>
                <div>Nächstes Treffen bei { stringifyCashValue(nextMeetup, 0) }</div>
            </div>
            <div className="d-flex align-items-center icon-balance-refresh" onClick={ e => updateBalanceHandler(e) }>
                <i className="fa-solid fa-rotate"></i>
            </div>
        </div>
    );
}

export default Balance;