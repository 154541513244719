import { useState } from "react";
//import { useNavigation } from "react-router-dom";

import { encryptAES, hashSHA512 } from "../../../lib/encryptionUtils";

function PasswordChangeForm() {
    //@TODO: Prio2: State-Variablen sollen alle in einem Objekt gespeichert werden
    const [passwordChangeStatus, setPasswordChangeStatus]       = useState("");
    const [cssPasswordChangeStatus, setCssPasswordChangeStatus] = useState("");

    const [oldPassword, setOldPassword]                 = useState("");
    const [newPassword, setNewPassword]                 = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm]   = useState("");

    
    //@TODO: soll den Button disablen, funktioniert nur mit action-Funktion
    //const navigation = useNavigation();
    //const isSubmitting = (navigation.state === 'submitting');

    //@TODO: Prio1: Statt onSubmitForm sollen action-Funktionen verwendet werden (siehe Lektion ~330)
    const onSubmitForm = async e => {
        e.preventDefault();
    
        setPasswordChangeStatus("");
    
        try {
            const body = {
                oldPassword,
                newPassword,
                newPasswordConfirm,
                newUserspecificKey: encryptAES(localStorage.dataKey, hashSHA512(newPassword))
            };
    
            if (newPassword !== newPasswordConfirm) {
                setCssPasswordChangeStatus("profil-password-change-status-red");
                setPasswordChangeStatus("Die beiden Passwörter stimmen nicht überein");
            } else {
                //@TODO: Erstelle eine POST-Methode in backendUtils, die route und body übernimmt (für alle post-anfragen (außer login) wird Token gebraucht)
                if (localStorage.token) {
                    const response = await fetch(process.env.REACT_APP_BACKEND_URL + "/profil/password", {
                        method: "POST",
                        headers: {
                            "Content-type": "application/json",
                            token: localStorage.token
                        },
                        body: JSON.stringify(body)
                    });
                    const passwordChangeResult = await response.json();
    
                    setOldPassword("");
                    setNewPassword("");
                    setNewPasswordConfirm("");
    
                    if (passwordChangeResult.success) {
                        setCssPasswordChangeStatus("profil-password-change-status-green");
                        setPasswordChangeStatus("Passwort wurde erfolgreich geändert");
                    } else {
                        setCssPasswordChangeStatus("profil-password-change-status-red");
                        if(!passwordChangeResult.reason) {
                            if (passwordChangeResult.err === 'err_invalid_password') {
                                setPasswordChangeStatus("Das aktuelle Passwort ist nicht korrekt");
                            } else {
                                setPasswordChangeStatus("Passwort konnte nicht geändert werden");
                            }
                        } else {
                            switch (passwordChangeResult.reason[0]) {
                                case 'min':
                                    setPasswordChangeStatus("Das Passwort muss mindestens 7 Zeichen lang sein"); break;
                                case 'max':
                                    setPasswordChangeStatus("Das Passwort darf maximal 100 Zeichen lang sein"); break;
                                case 'uppercase':
                                    setPasswordChangeStatus("Das Passwort muss Großbuchstaben enthalten"); break;
                                case 'lowercase':
                                    setPasswordChangeStatus("Das Passwort muss Kleinbuchstaben enthalten"); break;
                                case 'digits':
                                    setPasswordChangeStatus("Das Passwort muss eine Zahl enthalten"); break;
                                case 'symbols':
                                    setPasswordChangeStatus("Das Passwort muss ein Sonderzeichen enthalten"); break;
                                default: 
                                    setPasswordChangeStatus("Passwort konnte nicht geändert werden");
                            }
                        }
                    }
                }
            }
        } catch (err) {
            console.error(err.message);
        }
    }

    return (
        <form method='POST' onSubmit={ onSubmitForm }>
            <div className='d-flex row mb-2'>
                <div className='d-flex col-6 justify-content-end align-items-center profil-password-text px-1'>Aktuelles Passwort:</div>
                <div className='col-lg-3 col-5 px-1'>
                    <input type='password' className='profil-password-input' name='oldPassword' placeholder='Aktuelles Passwort' value={oldPassword} onChange={event => setOldPassword(event.target.value)}></input>
                </div>
            </div>

            <div className='d-flex row mb-2'>
                <div className='d-flex col-6 justify-content-end align-items-center profil-password-text px-1'>Neues Passwort:</div>
                <div className='col-lg-3 col-5 px-1'>
                    <input type='password' className='profil-password-input' name='newPassword' placeholder='Neues Passwort' value={newPassword} onChange={event => setNewPassword(event.target.value)}></input>
                </div>
            </div>

            <div className='d-flex row mb-2'>
                <div className='d-flex col-6 justify-content-end align-items-center profil-password-text px-1'>Neues Passwort bestätigen:</div>
                <div className='col-lg-3 col-5 px-1'>
                    <input type='password' className='profil-password-input' name='newPasswordConfirm' placeholder='Neues Passwort' value={newPasswordConfirm} onChange={event => setNewPasswordConfirm(event.target.value)}></input>
                </div>
            </div>

            <div className='d-flex row'>
                <div className='col-6'></div>
                <div className='col-lg-3 col-5 px-1'>
                    <button type='submit' className='btn btn-primary w-100' onClick={event => event.target.blur()}>Speichern</button>
                </div>
            </div>

            <div className={ "d-flex justify-content-center mt-3 " + cssPasswordChangeStatus }>{ passwordChangeStatus }</div>
        </form>
    );
}

export default PasswordChangeForm;