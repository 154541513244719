import { Outlet, useNavigation } from "react-router-dom";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer"

import './style/Members.css';

function MembersLayout() {
    const username = localStorage.username;

    //@TODO: Statt Lade Daten... soll hier ein Overlay hin, dass alles (bis auf Navbar und Footer) hellgrau macht und eine Lade-Schlage zeigt
    //  nur wenn eine Seite in der Navi angeklickt wird

    //@TODO: Titel der Seite in MembersLayout ziehen (???)
    const navigation = useNavigation();

    return (
        <div className="body">
            <Navbar username={ username } />

            <div className="content">
                { (navigation.state === 'loading') && "Lade Daten..."}
                { (navigation.state !== 'loading') && <Outlet /> }
            </div>

            <Footer />
        </div>
    );
}

export default MembersLayout;