import { Link, useNavigate } from "react-router-dom";

import Balance from "./Balance";

import '../style/Navbar.css';

import imgLogo from '../img/logo.png';

//@TODO: Get profile-pic from database (also in ../members/Profil.js)
import imgProfile from '../img/profile.png';

function Navbar(props) {
    const username  = props.username;

    const navigate = useNavigate();

    const logout = () => {
        try {
            localStorage.clear();
        } catch (err) {
            console.error(err.message);
        }

        navigate('/');
    }

    return (
        <div className="sticky-top">
            <nav className="navbar navbar-expand-lg navbar-dark navbar-bg">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand">
                        <img className="img-logo" src={ imgLogo } alt="" />
                    </Link>

                    <button className="btn d-lg-none btn-toggle" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasResponsive">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
                            <path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22M4 15h22M4 23h22" />
                        </svg>
                    </button>

                    <div className="offcanvas-lg offcanvas-end offcanvas-size" data-bs-backdrop="true" tabIndex="-1" id="offcanvasResponsive">
                        <div className="offcanvas-header d-flex d-lg-none flex-column canvas-header-bg">
                            <div className="d-flex col-12 justify-content-end">
                                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive"></button>
                            </div>

                            <div className="d-flex flex-column balance-item">
                                <div className="d-flex justify-content-center">
                                    <img className="img-profile-toggled" src={ imgProfile } alt="" />
                                </div>
                                <div className="username">{ username }</div>
                                <Balance />
                            </div>
                        </div>
                        <div className="offcanvas-body p-0">
                            <ul className="navbar-nav flex-grow-1">
                                <li className="nav-item d-none d-lg-flex justify-content-end">
                                    <Link to="training" className="nav-link active">Trainingskalender</Link>
                                </li>
                                {/*<li className="nav-item d-none d-lg-flex justify-content-end">
                                    <Link to="belohnungen" className="nav-link active">Belohnungen</Link>
                                </li>*/}
                                <li className="nav-item dropdown">
                                    <Link to="#" className="nav-link" id="navbarDropdown" role="button" data-bs-toggle="dropdown">
                                        <img className="d-none d-lg-block img-profile-untoggled" src={ imgProfile } alt="" />
                                    </Link>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li className="d-none d-lg-block dropdown-item balance-item">
                                            <div className="username">{ username }</div>
                                            <Balance />
                                        </li>
                                        <li data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
                                            <Link to="training" className="dropdown-item d-lg-none">Trainingskalender</Link>
                                        </li>
                                        {/*<li data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
                                            <Link to="belohnungen" className="dropdown-item d-lg-none">Belohnungen</Link>
                                        </li>*/}
                                        <li className="d-lg-none">
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li data-bs-dismiss="offcanvas" data-bs-target="#offcanvasResponsive">
                                            <Link to="profil" className="dropdown-item">Mein Profil</Link>
                                        </li>
                                        <li className="d-none d-lg-block">
                                            <hr className="dropdown-divider" />
                                        </li>
                                        <li>
                                            <Link onClick= { logout } className="dropdown-item">Abmelden</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;