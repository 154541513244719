import { redirect } from "react-router-dom";

async function fetchSiteData(route) {
    try {
        if (localStorage.token) {
            const res = await fetch(process.env.REACT_APP_BACKEND_URL + route, {
                method: "GET",
                headers: { token: localStorage.token }
            });
  
            if (res.status === 200) {
                return res;
            } else {
                localStorage.clear();
                return redirect("/");
            }
        
        } else {
            localStorage.clear();
            return redirect("/");
        }
    } catch (err) {
        console.error(err.message);
    }
}

export default fetchSiteData;