import HeartrateChart from './HeartrateChart';

import './EntryDetails.css'

function EntryDetails(props) {
    const { timestamp, distance, heartrate_min : heartrateMin, heartrate_data : heartrateData } = props.data

    return ( 
        <div className="container workout-details">
            <div className="d-flex justify-content-center">
                Uhrzeit: {(new Date(timestamp)).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
            </div>
            <div className="row">
                <div className="col-md-4 row">
                        <div className="col-md-12 col-6 mt-2">
                            <div className="d-flex justify-content-start label-headline">
                                Distanz
                            </div>
                            <hr className="label-divider mb-1" />
                            <div className="d-flex justify-content-end">
                                { distance } km    
                            </div>
                        </div>

                    
                        <div className="col-md-12 col-6 mt-2">
                            <div className="d-flex justify-content-start label-headline">
                                Minimalpuls
                            </div>
                            <hr className="label-divider mb-1" />
                            <div className="d-flex justify-content-end">
                                { heartrateMin } Hz    
                            </div>
                        </div>
                </div>

                <div className="d-flex col-md-8 align-items-center heartrate-chart">
                    <HeartrateChart heartrateData={ heartrateData }/>
                </div>
            </div>
        </div>
    );
}

export default EntryDetails;