import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function HeartrateChart({ heartrateData }) {
    const options = {
        responsive: true,
        scales: {
            x: {
                display: false,
            },
            y: {
                grid: {
                    display: false
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: false,
            },
        },
    };
      
    const labels = heartrateData;
    
    const data = {
        labels,
        datasets: [
            {
                borderColor: "rgba(110, 27, 145, 0.8)",
                borderWidth: 1,
                data: labels,
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                pointRadius: 0,
            }
        ],
    };

    return <Line options={ options } data={ data } />;
}

export default HeartrateChart;