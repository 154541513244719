import EntryDetails from "./EntryDetails";

import checkTick from '../../img/check_tick.png';
import checkCross from '../../img/check_cross.png'

function CalendarEntry(props) {
    const day = props.day;
    const status_code =  props.details.status_code;
    const status = (status_code === 101) ? "Pause" : (props.details.duration + " Minuten");

    const dayStrings = [ "MO", "DI", "MI", "DO", "FR", "SA", "SO" ];
    
    let entryStatusIcon = "";
    let entryDetails = "";
    switch (status_code) {
        case 100:
            entryDetails = <EntryDetails data={props.details} />;
            entryStatusIcon = <img src={ checkTick } alt="" />;
            break;
        case 101:
            entryDetails = "Für diesen Tag ist keine Trainingseinheit geplant.";
            break;
        case 102:
            entryDetails = "Diese Trainingseinheit muss erst absolviert werden.";
            break;
        case 103:
            entryDetails = "Diese Trainingseinheit wurde nicht gemacht.";
            entryStatusIcon = <img src={ checkCross } alt="" />;
            break;
        default:
            entryDetails = "Es konnten keine Details abgerufen werden.";
    }

    return (
        <div className="w-100 col-10 accordion-item mb-1">
            <ul className="list-group list-group-horizontal calendar-entry" data-bs-toggle="collapse" data-bs-target={ "#collapse" + day }>
                <li className="list-group-item d-flex justify-content-center calendar-weekday">
                    { dayStrings[day] }
                </li>

                <li className="list-group-item d-flex justify-content-center w-100 calendar-textfield collapsed" data-bs-toggle="collapse" data-bs-target={ "#collapse" + day }>
                    { status }
                </li>
                
                <li className="list-group-item calendar-status d-flex justify-content-center align-items-center">
                    { entryStatusIcon }
                </li>
            </ul>
            <div id={ "collapse" + props.day } className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                <div className="accordion-body calendar-details d-flex justify-content-center">
                    { entryDetails }
                </div>
            </div>
        </div>
    );
}

export default CalendarEntry;