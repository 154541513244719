import CryptoJS from "crypto-js";

function hashSHA512(text) {
    return CryptoJS.SHA512(text).toString();
}

function encryptAES(text, secret) {
    return CryptoJS.AES.encrypt(text, secret).toString();
}

function encryptAESText(text) {
    let encryptedText = "";

    if (localStorage.dataKey) {
        encryptedText = encryptAES(text, localStorage.dataKey);
    }
        
    return encryptedText;
}

function decryptAES(text, secret) {
    return CryptoJS.AES.decrypt(text, secret).toString(CryptoJS.enc.Utf8);
}

function decryptAESText(text) {
    let decryptedText = "";

    if (localStorage.dataKey) {
        decryptedText = decryptAES(text, localStorage.dataKey);
    }

    return decryptedText;
}

export {
    hashSHA512,
    encryptAES,
    encryptAESText,
    decryptAES,
    decryptAESText
}